'use strict';

import * as Collapsible from '@radix-ui/react-collapsible';
import cls from 'classnames';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {FilterIcon} from '../CustomIcons/FilterIcon';
import {Select} from '../FormFields/Select';
import {Property} from '../Partials/Property';
import {getProperties, PropertiesPagination} from '../Services/property';
import {Listing, SelectItem} from '../Types';
import {SelectRegion, useDistricts} from './SelectRegion';

const propertiesPerPage = 32;

interface Filters {
    setRegion: Dispatch<SetStateAction<SelectItem | null>>;
    region: SelectItem | null;
    districts: [] | SelectItem[];
    setDistrict: Dispatch<SetStateAction<SelectItem | null>>;
    district: SelectItem | null;
    setBedrooms: Dispatch<SetStateAction<SelectItem | null>>;
    bedrooms: SelectItem | null;
    setRent: Dispatch<SetStateAction<SelectItem | null>>;
    rent: SelectItem | null;
}

const InfiniteScrollLoader = () => {
    return (
        <div className="mx-auto w-full mb-20 mt-10">
            <p className="text-brand-blue text-center sm:text-2.5xl text-lg">Loading...</p>
        </div>
    );
};

const PropertiesList = ({properties, fetchMoreData, pagination, loading}: { loading: boolean, properties: Listing[], fetchMoreData: () => void, pagination: PropertiesPagination | null }) => (
    properties?.length > 0 ?
        <InfiniteScroll
            next={fetchMoreData}
            dataLength={properties.length}
            hasMore={!!pagination?.notLastPage}
            loader={<InfiniteScrollLoader/>}>
            <div className="grid xl:grid-cols-4 lg:grid-cols-3 gap-6 sm:grid-cols-2 grid-cols-1">
                {properties.map((property, index) => (
                    <Property key={index} listing={property}/>
                ))}
            </div>
        </InfiniteScroll>
        :
        <div className="mx-auto w-full mb-20 mt-10">
            <p className="text-brand-blue text-center sm:text-2.5xl text-lg">
              {loading ? 'Loading...' : 'There are no properties available with the given filter values'}
            </p>
        </div>
);

const Filters = ({setRegion, region, districts, setDistrict, district, setRent, rent, bedrooms, setBedrooms}: Filters) => (
    <div className="flex 2xl:flex-row flex-col 2xl:gap-y-0 gap-x-5 gap-y-5 justify-between my-11">
        <div className="flex md:flex-row md:space-y-0 space-y-4 flex-col md:gap-5 items-center">
            <span className="text-brand-blue">Show me rentals in</span>
            <SelectRegion regionChanged={setRegion}/>
            <Select className={cls('transition ease-in-out duration-300', region?.id ? 'opacity-100' : 'opacity-0')}
                    title="Select district" data={districts} setSelectedItem={setDistrict} selectedItem={district}/>
        </div>
        <div className="flex md:flex-row md:space-y-0 space-y-4 flex-col md:gap-5 items-center">
            <div className="flex space-x-2.5">
                <span className="text-brand-blue">Filter</span>
                <FilterIcon/>
            </div>
            <Select red={false} title="Rent per week" data={[]} setSelectedItem={setRent} selectedItem={rent}/>
            <Select red={false} title="Bedrooms" data={[]} selectedItem={bedrooms} setSelectedItem={setBedrooms}/>
        </div>
    </div>);


export const PropertyHolder = () => {

    const [properties, setProperties] = useState<Listing[]>([]);
    const [page, setPage] = useState<number>(0);
    const [pagination, setPagination] = useState<PropertiesPagination | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(true);

    const [region, setRegion] = useState<SelectItem | null>(null);
    const {district, setDistrict, districts} = useDistricts(region);

    const [rent, setRent] = useState<SelectItem | null>(null);
    const [bedrooms, setBedrooms] = useState<SelectItem | null>(null);

    useEffect(() => {
        if(page === 0) return;
        setLoading(true);
        const {request, abortController} = getProperties(page, propertiesPerPage);

        request.then(({data, pagination}) => {
            if (!abortController?.signal.aborted) {
                setPagination(pagination);
                if (data?.length > 0) {
                    setProperties((currentData) => [
                        ...currentData,
                        ...data,
                    ]);
                }
            }
        }).finally(() => {
            setLoading(false);
        });
        return () => abortController?.abort();
    }, [page]);

    useEffect(() => {
        const {request, abortController} = getProperties(0, propertiesPerPage, `region=${region?.id ?? ''}&district=${district?.id ?? ''}`);
        setLoading(true);
        request.then(({data, pagination}) => {
            if (!abortController?.signal.aborted) {
                setPagination(pagination);
                setPage(0);
                setProperties(data);
            }
        }).catch((error) => {
                if (error.name !== 'AbortError') {
                    throw error;
                }
            },
        ).finally(() => {
            setLoading(false);
        });
        return () => abortController?.abort();
    }, [region, district]);

    const fetchMoreData = () => {
        setPage((currentPage) => currentPage + 1);
    };

    return (
        <div>
            <div className="md:hidden block mb-10">
                <Collapsible.Root open={open} onOpenChange={setOpen}>
                    <Collapsible.Content className="space-y-6 overflow-hidden data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp">
                        <Filters
                            setRegion={setRegion}
                            region={region}
                            districts={districts}
                            setDistrict={setDistrict}
                            district={district}
                            setBedrooms={setBedrooms}
                            bedrooms={bedrooms}
                            setRent={setRent}
                            rent={rent}
                        />
                    </Collapsible.Content>
                    <Collapsible.Trigger className="flex text-brand-red  items-center mx-auto">
                        <span className="text-brand-red font-semibold">{open ? 'Hide filters' : 'Show Filters'}</span>
                        <svg width="40" className={cls('ease-in-out duration-7=300', open ? 'rotate-180' : 'rotate-0')} height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="20" cy="20" r="14" className="stroke-brand-red" strokeWidth="2"/>
                            <path d="M23.364 18.2933C23.7545 18.6838 23.7545 19.317 23.364 19.7075L20.5356 22.5359C20.145 22.9265 19.5119 22.9265 19.1213 22.5359C18.7308 22.1454 18.7308 21.5123 19.1213 21.1217L21.9498 18.2933C22.3403 17.9028 22.9735 17.9028 23.364 18.2933Z" fill="currentColor"/>
                            <path d="M20.5356 22.5359C20.145 22.9265 19.5119 22.9265 19.1213 22.5359L16.2929 19.7071C15.9024 19.3166 15.9024 18.6834 16.2929 18.2929C16.6834 17.9024 17.3166 17.9024 17.7071 18.2929L20.5356 21.1213C20.9261 21.5118 20.9261 22.1454 20.5356 22.5359Z" fill="currentColor"/>
                        </svg>
                    </Collapsible.Trigger>
                </Collapsible.Root>
            </div>
            <div className="hidden md:block">
                <Filters setRegion={setRegion}
                         region={region}
                         districts={districts}
                         setDistrict={setDistrict}
                         district={district}
                         setBedrooms={setBedrooms}
                         bedrooms={bedrooms}
                         setRent={setRent}
                         rent={rent}/>
            </div>
            <PropertiesList loading={loading} properties={properties} fetchMoreData={fetchMoreData} pagination={pagination}/>
        </div>
    );
};
