'use strict';
import React from 'react';

export const FilterIcon = () => (
    <div className="flex gap-4 items-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none">
            <circle cx="4" cy="4" r="3" stroke="#005395" strokeWidth="2"/>
            <circle cx="16" cy="13" r="3" transform="rotate(-180 16 13)" stroke="#005395" strokeWidth="2"/>
            <path d="M7 4H15" stroke="#005395" strokeWidth="2" strokeLinecap="round"/>
            <path d="M13 13L5 13" stroke="#005395" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    </div>
);

